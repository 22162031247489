<template>
  <v-container fluid>
    <v-row justify="start" class="grey-2 mt-3">
      <v-col cols="12">
        <h2 class="grey--text">Sincronização - Cache</h2>
      </v-col>
    </v-row>
    <v-form class="mt-7 white">
      <v-row class="mx-1">
        <v-col class="pt-5">
          <label class="grey--text font-weight-medium">Pesquisar</label>
        </v-col>
      </v-row>
      <v-row justify="start" class="mx-1">
        <v-col cols="12" sm="6" md="3">
          <label class="label">Tipo de configuração</label>
          <v-autocomplete
            v-model="entitiesFilter"
            :items="entityOptions"
            color="newPrimary"
            outlined
            multiple
            placeholder="Selecione"
            clearable
          >
            <template v-slot:selection="data">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.text }} </v-chip>
                </template>
                <span>{{ data.item.text }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn
            class="white--text mt-6"
            style="height: 55px;"
            x-large
            block
            color="newPrimary"
            :loading="loadingManagementImplantations"
            :disabled="disableControl()"
            @click="onClickSearch();"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="end" class="mx-1">
        <v-col align="end" cols="12">
          <v-btn
            class="white--text"
            x-large
            color="newPrimary"
            :loading="loadSync"
            :disabled="selectedItems.length === 0"
            @click="onClickSync()"
          >
            <v-icon class="mr-2" size="24px">
              mdi mdi-refresh
            </v-icon>
            Sincronizar Selecionados
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedItems"
            class="elevation-1"
            show-select
            item-key="index"
            @toggle-select-all="handleSelectionChange"
            @update:options="changePage($event)"
            :options.sync="options"
            :headers="configurationHeader"
            :items="entities"
            :page="page"
            :loading="loadingManagementImplantations"
            :server-items-length="totalPages"
            :items-per-page="itemsPerPage"
            :footer-props="{
              itemsPerPageOptions: [10, -1],
              disablePagination: loadingManagementImplantations,
            }"
          >
            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.disabled"
                :disabled="item.disabled"
                color="primary"
                @input="select($event)"
              />
            </template>
            <template v-slot:[`item.entity`]="{ item }">
              <span>{{ item.entity ? formatEntity(item.entity)  : '-' }}</span>
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
              <span>{{ item.updatedAt ? formatter.formatDateTimeZoneWithHours(item.updatedAt) : '-' }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-btn :style="{ cursor: 'auto' }" dark :color="formatedStatus(item.status).color" prepend-icon="mdi-check-circle">
                <v-icon size="16px" class="mr-2">{{ formatedStatus(item.status).icon }}</v-icon>
                {{ formatedStatus(item.status).text }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>

    <v-footer class="mt-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickBack()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import ClearCacheService from '@/services-http/sdi/ClearCacheService';
import Formatters from '@/shared/formatters/formatters';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';

export default {
  name: 'ManagementSyncs',
  components: { SnackbarCustomize },
  data: () => ({
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingManagementImplantations: false,
    loadSync: false,
    isRHProtegido: false,

    page: 1,
    totalPages: 1,
    itemsPerPage: 10,

    options: {},

    status: 'PENDING',
    entityType: '',
    entities: [],
    entitiesFilter: [],
    selectedItems: [],
    managementImplantations: [],
    entityOptions: [
      {
        value: 'BENEFIT',
        text: 'Benefício',
      },
      {
        value: 'CONTRACT',
        text: 'Contrato/Sub',
      },
      {
        value: 'CONTRACT_ELIGIBILITY',
        text: 'Elegibilidade',
      },
      {
        value: 'FINANCIAL_GROUP',
        text: 'Grupo Financeiro',
      },
      {
        value: 'CARRIER',
        text: 'Operadora',
      },
      {
        value: 'PLAN',
        text: 'Plano',
      },
      {
        value: 'CONTRACT_PLAN',
        text: 'Plano Contratado',
      },
      {
        value: 'PLAN_PRICE',
        text: 'Preço Plano',
      },
      {
        value: 'REASON_CANCELLATION',
        text: 'Motivo de Cancelamento',
      },
      {
        value: 'UNIT',
        text: 'Unidade',
      },
      {
        value: 'PLAN_CHANGE_REASON',
        text: 'Motivo Alteração',
      },
    ],
  }),

  mixins: [
    ConstantsMixin,
    VerifyRoutesMixin,
  ],
  computed: {
    configurationHeader() {
      const commonHeaders = [
        {
          text: 'Tipo de configuração',
          value: 'entity',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
        {
          text: 'Última Execução',
          value: 'updatedAt',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
        {
          text: 'Usuário',
          value: 'userName',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
      ];

      return commonHeaders;
    },
  },

  methods: {
    onClickSearch() {
      const searchParams = this.createSearchParams();
      this.searchCache(searchParams);
    },
    createSearchParams() {
      let payload = {};
      payload = { entities: this.entitiesFilter };
      return payload;
    },
    async searchCache(searchParams) {
      this.loadingManagementImplantations = true;
      const parameterFilter = Object.entries(searchParams).filter(([key, value]) => value !== null && value.length !== 0).reduce((params, [key, value]) => ({ ...params, [key]: value }), {});
      const params = new URLSearchParams(parameterFilter);
      this.clearCacheService.Filter(params).then((response) => {
        if (response && response.data) {
          this.entities = response.data;

          this.adjustedManagementImplantations();
        } else {
          this.entities = [];
        }
        this.loadingManagementImplantations = false;
      }).catch(() => {
        this.loadingManagementImplantations = false;
      });
    },
    adjustedManagementImplantations() {
      let count = 0;
      this.entities.forEach((element) => {
        if (element.status !== 'DONE' && element.status !== 'ERROR') {
          element.disabled = true;
        }
        // eslint-disable-next-line no-plusplus, no-param-reassign
        element.index = count++;
      });
    },
    formatedStatus(status) {
      if (status) {
        if (status === 'ERROR') return { icon: 'mdi mdi-close', color: 'red', text: 'Falha no Processamento' };
        if (status === 'DONE') return { icon: 'mdi mdi-check', color: 'success', text: 'Configuração Ativa' };
        if (status === 'PENDING_FORCE_SYNC' || (status === 'PENDING')) return { icon: 'mdi mdi-play-outline', color: 'blue', text: 'Em Processamento' };
      }
      return null;
    },
    handleSelectionChange(data) {
      if (this.rule.isEmptyArray(this.selectedItems)) {
        const foundSelected = data.items.filter((item) => item.status === 'DONE' || item.status === 'ERROR');
        this.selectedItems = foundSelected;
      } else {
        this.selectedItems = [];
      }
    },
    async onClickSync() {
      this.loadSync = true;
      const payload = { entities: this.selectedItems.map((e) => e.entity) };

      await this.clearCacheService.ForceSync(payload).then(() => {
        setTimeout(() => {
          this.selectedItems = [];
          this.$refs.SnackbarCustomize.open('blue', 'A sincronização iniciará em instantes', 5000);
          this.loadSync = false;
          this.onClickSearch();
        }, 1000);
      }).catch(() => {
        this.loadSync = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao sincronizar');
      });
    },
    changePage() {
      this.selectedItems = [];
    },
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    formatEntity(entity) {
      return this.entityOptions.filter((e) => e.value === entity)[0].text;
    },
    disableControl() {
      return false;
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.clearCacheService = new ClearCacheService();
  },
};
</script>
