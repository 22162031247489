/* eslint-disable */
import HttpService from '../HttpService';

export default class ClearCacheService {
  constructor() {
    this._httpService = new HttpService('/sdi/clear-cache');
  }

  async Filter(params) {
    return await this._httpService.get('', params);
  }

  async ForceSync(body) {
    return await this._httpService.post('', body);
  }
}
